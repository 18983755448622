import React, { Component } from 'react';
//import { useNavigate } from 'react-router-dom';

class Sidemenu extends Component{
    constructor(props){
        super(props)
        this.state = {
            user:''
        
        }
        this.logout = this.logout.bind(this);
    }
    componentDidMount(){
        var user = localStorage.getItem("user");
        user = JSON.parse(user);
        if(user){
            console.log(user)
			this.setState({user:user});
		}
    }
    logout(){
        localStorage.removeItem('user');
        this.setState({loged: false});
        window.location.href = '/login'
        
    }
    render(){
        return(
        <div className="col s2 left-menu">
            <ul>
                <li active><a href = "/user-report">
                <span>User report</span>
                </a></li>
                <li active><a href = "/mint-report">
                <span>Mint report</span>
                </a></li>
                <li active><a href = "/bid-report">
                <span>Bid Report</span>
                </a></li>
                <li style={{cursor:'pointer'}}><a onClick={this.logout} >
                <span>Log out</span>
                </a></li>
            </ul>
             
        </div>
        );
    }

}
export default Sidemenu;