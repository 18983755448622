import React, { Component } from "react";

class Navbar extends Component{
    constructor(props){
        super(props)
        this.state = {
            loged:false,
            viseable: false,
            userype:0,
            showM:false
        };
        this.logout = this.logout.bind(this)
        this.showMore = this.showMore.bind(this)
    }
    showMore(){
        var user = localStorage.getItem('user');
        if(user){
            this.setState({viseable: !this.state.viseable });
        }
    }
    logout(){
        localStorage.removeItem('user');
        window.location.href = '/login'
        this.setState({loged: false})
        //this.props.history.push('/login');
    }
    componentDidMount(){
        var user = localStorage.getItem('user');
        if(user){
            this.setState({loged: true})
            var u = JSON.parse(user);
            this.setState({userype: u.user_type})
        }else{
            this.setState({loged: false})
        }
    }


    render(){
        return(
            <>
            <header className="header__1 js-header" id="header">
                    <div className="container">
                        <div className="wrapper js-header-wrapper">
                            <div className="header__logo">
                                <a href="/">
                                    <img
                                        className="header__logo"
                                        id="logo_js"
                                        src="./logo_nft.png"
                                        alt="logo"
                                        />
                                </a>
                            </div>

                            <div className="header__menu">
                                <ul className="d-flex space-x-20">
                                    <li>
                                        <a className="color_black" href="http://nftlimitedseries.com/"> Home</a>
                                    </li>
                                    <li>
                                        <a className="color_black" href="http://nftlimitedseries.com/marketplace"> Marketplace</a>
                                    </li>
                                    <li>
                                        <a className="color_black" href="http://nftlimitedseries.com/collection"> Collections</a>
                                    </li>
                                    <li>
                                        <a className="color_black" href="http://nftlimitedseries.com/profile"> Profile</a>
                                    </li>
                                    <li>
                                        <a className="color_black" href="http://nftlimitedseries.com/upload-type"> Creators</a>
                                    </li> 
                                    <li class="has_popup2">
									<a class="color_black is_new" 
                                    onClick={this.showMore }
                                    >Pages <i class="ri-more-2-fill"></i></a>
									<ul className={`menu__popup2 space-y-20  ${this.state.viseable ? 'visible' : ''}`}>
										<div class="row sub_menu_row">
										
											<div class="col-lg-6 space-y-10">
												
												{/* <li>
													<a href="Activity.html">
														<i class="ri-line-chart-line"></i>
														Activity
													</a>
												</li> */}
												
												<li>
													<a class="is_new" href="http://99.79.110.129/profile" 
                                                    >
														<i class="ri-edit-line"></i>
														Edit Profile
													</a>
												</li>
										
												
												{/* <li>
													<a href="Item-details.html">
														<i class="ri-gallery-line"></i>
														Item details
													</a>
												</li>
												
												<li>
													<a class="is_new" href="Live_Auctions.html">
														<i class="ri-auction-line"></i>
														Live Auctions
													</a>
												</li> */}
												
												{/* <li>
													<a href="Upcoming_projects.html">
														<i class="ri-upload-line"></i>
														Upcoming projects
													</a>
												</li>
												
												<li>
													<a class="is_new" href="ranking.html">
														<i class="ri-funds-line"></i>
														Ranking
													</a>
												</li>
										
												
												<li>
													<a class="is_new" href="newsletter.html">
														<i class="ri-mail-open-line"></i>
														Newsletter
													</a>
												</li> */}
												
												{/* <li>
													<a href="forum.html">
														<i class="ri-discuss-line"></i>
														Forum & community
													</a>
												</li>
												
												<li>
													<a href="post_details.html">
														<i class="ri-chat-check-line"></i>
														Forum details
													</a>
												</li> */}
										
												
												{/* <li>
													<a href="no_results.html">
														<i class="ri-file-search-line"></i>
														No Result
													</a>
												</li>
										
												
												<li>
													<a class="is_new" href="Contact.html">
														<i class="ri-customer-service-2-line"></i>
														Contact
													</a>
												</li> */}
										
											</div>
										
										
										
										
										
										
										
											<div class="col-lg-6 space-y-10">
										
												
												<li>
													<a href="http://nftlimitedseries.com/upload-type">
														<i class="ri-upload-line"></i>
														Upload Types
													</a>
												</li>
												
												{/* <li>
													<a href="Connect-wallet.html">
														<i class="ri-wallet-3-line"></i>
														Connect wallet
													</a>
												</li>
										
												
												<li>
													<a href="questions.html">
														<i class="ri-question-line"></i>
														FAQ
													</a>
												</li>
												
												<li>
													<a class="is_new" href="Submit_request.html">
														<i class="ri-share-forward-line"></i>
														Submit request
													</a>
												</li>
												
												<li>
													<a class="is_new" href="Submit_request.html">
														<i class="ri-message-3-line"></i>
														Request chat
													</a>
												</li>
												
												<li>
													<a class="is_new" href="blog.html">
														<i class="ri-layout-line"></i>
														Blog
													</a>
												</li>
												
												<li>
													<a class="is_new" href="article.html">
														<i class="ri-newspaper-line"></i>
														Article
													</a>
												</li> */}
												
												<li>
													<a href="http://nftlimitedseries.com/registrion">
														<i class="ri-lock-line"></i>
														Register
													</a>
												</li>
												
												<li>
													<a href="http://nftlimitedseries.com/login">
														<i class="ri-shield-user-line"></i>
														Login
													</a>
												</li>
												
												{/* <li>
													<a href="Privacy.html">
														<i class="ri-file-text-line"></i>
														Privacy Policy
													</a>
												</li>
												
												<li>
													<a c href="404-2.html">
														<i class="ri-file-damage-line"></i>
														404
													</a>
												</li> */}
											</div>
										</div>						</ul>
								</li>
                                {this.state.userype ==0 | this.state.userype =='0' &&
                                <li>
                                        <a className="color_black" href="http://nftlimitedseries.com/history"> History</a>
                                    </li> 
                                }
                                </ul>
                            </div>
                            {/* <div className="header__search">
                                <input type="text" placeholder="Search" />
                                <button className="header__result">
                                    <i className="ri-search-line"></i>
                                </button>
                            </div> */}
                            <div className="header__btns">
                                <a className="btn btn-grad btn-sm" href="#">
                                    <i className="ri-wallet-3-line"></i>
                                    Connect wallet
                                    </a>
                                    {this.state.loged && 
                                    <button type="button" className="" onClick={this.logout}>
                                    <i className="fa fa-sign-out"></i>
                                    
                                    </button>}
                                
                            </div>
                            <div 
                            className={
                                "header__burger js-header-burger " +
                                (this.state.showM ? "active" : "") 
                              }
                            
                            onClick={(e)=>{this.setState({showM: !this.state.showM})}}
                            ></div>

                            <div 
                            className={
                                "header__mobile js-header-mobile " +
                                (this.state.showM ? "visible" : "") 
                              }
                            >
                                <div className="header__mobile__menu space-y-40">
                                    <ul className="d-flex space-y-20">
                                        <li> <a className="color_black" href="http://nftlimitedseries.com/marketplace"> Marketplace</a> </li>
                                        <li> <a className="color_black" href="http://nftlimitedseries.com/collection"> Collections</a> </li>
                                        <li>
                                        <a className="color_black" href="http://nftlimitedseries.com/collection"> Collections</a>
                                        </li>
                                        <li>
                                            <a className="color_black" href="http://nftlimitedseries.com/profile"> Profile</a>
                                        </li>
                                        <li>
                                            <a className="color_black" href="http://nftlimitedseries.com/upload-type"> Creators</a>
                                        </li> 
                                         <li> <a className="color_black" onClick={this.logout}> Logout</a> </li>
                                        {/*
                                        <li> <a className="color_black" href="Creators.html"> Creators</a> </li> */}
                            
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <header className="header__1 js-header header_wallet" id="header_admin">
                    <div className="container">
                        <div className="wrapper js-header-wrapper space-x-10">
                            <div className="header__logo">
                                <a href="index.html">
                                    <img
                                        className="header__logo"
                                        id="logo_js"
                                        src="assets/img/logos/Logo.svg"
                                        alt="logo"
                                        />
                                </a>
                            </div>
                            <div className="header__menu">
                                <ul className="d-flex space-x-20">
                                    <li className="has_popup">
                                        <a className="color_black is_new" href="#">Homes <i className="ri-more-2-fill"></i></a>
                                        <ul className="menu__popup space-y-20">
                                            {/* <li>
                                                <a href="#">
                                                    <i className="ri-home-smile-2-line"></i>
                                                    Home page 1
                                                </a>
                                            </li>
                                            <li>
                                                <a href="Home2.html">
                                                    <i className="ri-home-2-line"></i> Home page 2</a>
                                            </li>
                                            <li>
                                                <a href="Home3.html">
                                                    <i className="ri-home-5-line"></i> Home page 3</a>
                                            </li>
                                            <li>
                                                <a href="Home5.html">
                                                    <i className="ri-home-5-line"></i> Home page 4</a>
                                            </li> */}
                                        </ul>
                                    </li>
                                    <li> <a className="color_black" href="http://nftlimitedseries.com/marketplace"> Marketplace</a> </li>
                                        <li> <a className="color_black" href="http://nftlimitedseries.com/collection"> Collections</a> </li>
                                    <li> <a className="color_black" href=""> Profile</a> </li>
                                    {/* <li> <a className="color_black" href="Creators.html"> Creators</a> </li> */}
                                    <li className="has_popup2">
                                        <a className="color_black is_new" href="#">Pages <i className="ri-more-2-fill"></i></a>
                                        <ul className="menu__popup2 space-y-20">
                                            <div className="row sub_menu_row">
                                            
                                                <div className="col-lg-6 space-y-10">
                                                    {/* <li>
                                                        <a href="Activity.html">
                                                            <i className="ri-line-chart-line"></i>
                                                            Activity
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="is_new" href="edit_profile.html">
                                                            <i className="ri-edit-line"></i>
                                                            Edit Profile
                                                        </a>
                                                    </li>
                                            
                                                    <li>
                                                        <a href="Item-details.html">
                                                            <i className="ri-gallery-line"></i>
                                                            Item details
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="is_new" href="Live_Auctions.html">
                                                            <i className="ri-auction-line"></i>
                                                            Live Auctions
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="Upcoming_projects.html">
                                                            <i className="ri-upload-line"></i>
                                                            Upcoming projects
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="is_new" href="ranking.html">
                                                            <i className="ri-funds-line"></i>
                                                            Ranking
                                                        </a>
                                                    </li>
                                            
                                                    <li>
                                                        <a className="is_new" href="newsletter.html">
                                                            <i className="ri-mail-open-line"></i>
                                                            Newsletter
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="forum.html">
                                                            <i className="ri-discuss-line"></i>
                                                            Forum & community
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="post_details.html">
                                                            <i className="ri-chat-check-line"></i>
                                                            Forum details
                                                        </a>
                                                    </li>
                                            
                                                    <li>
                                                        <a href="no_results.html">
                                                            <i className="ri-file-search-line"></i>
                                                            No Result
                                                        </a>
                                                    </li>
                                            
                                                    <li>
                                                        <a className="is_new" href="Contact.html">
                                                            <i className="ri-customer-service-2-line"></i>
                                                            Contact
                                                        </a>
                                                    </li> */}
                                            
                                                </div>
                                            
                                            
                                            
                                            
                                            
                                            
                                            
                                                <div className="col-lg-6 space-y-10">
                                            
                                                    {/* <li>
                                                        <a href="Upload-type.html">
                                                            <i className="ri-upload-line"></i>
                                                            Upload Types
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="Connect-wallet.html">
                                                            <i className="ri-wallet-3-line"></i>
                                                            Connect wallet
                                                        </a>
                                                    </li>
                                            
                                                    <li>
                                                        <a href="questions.html">
                                                            <i className="ri-question-line"></i>
                                                            FAQ
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="is_new" href="Submit_request.html">
                                                            <i className="ri-share-forward-line"></i>
                                                            Submit request
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="is_new" href="Submit_request.html">
                                                            <i className="ri-message-3-line"></i>
                                                            Request chat
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="is_new" href="blog.html">
                                                            <i className="ri-layout-line"></i>
                                                            Blog
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="is_new" href="article.html">
                                                            <i className="ri-newspaper-line"></i>
                                                            Article
                                                        </a>
                                                    </li> */}
                                                    <li>
                                                        <a href="./registrion">
                                                            <i className="ri-lock-line"></i>
                                                            Register
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/login">
                                                            <i className="ri-shield-user-line"></i>
                                                            Login
                                                        </a>
                                                    </li>
                                                    {/* <li>
                                                        <a href="Privacy.html">
                                                            <i className="ri-file-text-line"></i>
                                                            Privacy Policy
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a c href="404-2.html">
                                                            <i className="ri-file-damage-line"></i>
                                                            404
                                                        </a>
                                                    </li> */}
                                                </div>
                                            </div>						</ul>
                                    </li>
                                </ul>
                            </div>
                            <div className="header__search">
                                <input type="text" placeholder="Search" />
                                <button className="header__result">
                                    <i className="ri-search-line"></i>
                                </button>
                            </div>
                            <div className="d-flex align-items-center space-x-20">
                                <div className="header__notifications">
                                    <div className="js-notifications-icon">
                                        <i className="ri-notification-3-line"></i>
                                    </div>
                                    {/* <div className="notifications_popup space-y-20">
                                        <div className="d-flex justify-content-between">
                                            <h5> Notifications</h5>
                                            <a href="Activity.html" className="badge color_white">View all</a>
                                        </div>
                                        <div
                                            className="item
                                            space-x-20
                                            d-flex
                                            justify-content-between
                                            align-items-center">
                                            <img
                                                className="thumb"
                                                src="assets/img/notifications/1.png"
                                                alt="..."
                                                />
                                            <div className="details">
                                                <a href="404-3.html"> <h6>Money revieved</h6> </a>
                                                <p>0.6 ETH</p>
                                            </div>
                                            <span className="circle"></span>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="header__avatar">
                                    <div className="price">
                                        <span>2.45 <strong>ETH</strong> </span>
                                    </div>
                                    <img
                                        className="avatar"
                                        src="assets/img/avatars/avatar_2.png"
                                        alt="avatar"
                                        />
                                    {/* <div className="avatar_popup space-y-20">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span> 13b9ebda035r178... </span>
                                            <a href="index.html" className="ml-2">
                                                <i className="ri-file-copy-line"></i>
                                            </a>
                                        </div>
                                        <div className="d-flex align-items-center space-x-10">
                                            <img
                                                className="coin"
                                                src="assets/img/logos/coin.svg"
                                                alt="/"
                                                />
                                            <div className="info">
                                                <p className="text-sm font-book text-gray-400">Balance</p>
                                                <p className="w-full text-sm font-bold text-green-500">16.58 ETH</p>
                                            </div>
                                        </div>
                                        <div className="hr"></div>
                                        <div className="links space-y-10">
                                            <a href="#">
                                                <i className="ri-landscape-line"></i> <span> My items</span>
                                            </a>
                                            <a href="edit_profile.html">
                                                <i className="ri-pencil-line"></i> <span> Edit Profile</span>
                                            </a>
                                            <a href="#">
                                                <i className="ri-logout-circle-line"></i> <span> Logout</span>
                                            </a>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="header__btns">
                                    <a className="btn btn-primary btn-sm" href="Upload-type.html">Create</a>
                                </div>
                                <div className="header__burger js-header-burger"></div>
                            </div>
                            <div className="header__mobile js-header-mobile">
                                <div className="header__mobile__menu space-y-40">
                                    <ul className="d-flex space-y-20">
                                    <li> <a className="color_black" href="http://nftlimitedseries.com/marketplace"> Marketplace</a> </li>
                                        <li> <a className="color_black" href="http://nftlimitedseries.com/collection"> Collections</a> </li>
                                        {/* <li> <a className="color_black" href=""> Profile</a> </li>
                                        <li> <a className="color_black" href="Creators.html"> Creators</a> </li> */}
                            
                                    </ul>
                                    <div className="space-y-20">
                                        <div className="header__search in_mobile w-full">
                                            <input type="text" placeholder="Search" />
                                            <button className="header__result">
                                                <i className="ri-search-line"></i>
                                            </button>
                                        </div>
                                        <a className="btn btn-grad btn-sm" href="#">Connect
                                            wallet</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </>
        );
    }
}

export default Navbar;